:root {
  --color-primary: #31586d;
  --color-white: #ffffff;
  --color-red: #993538;
  --color-yellow: #e59b50;
  --color-background: #d8d1ca;
  --color-black: #000000;
}

body {
  margin: 0;
  font-family: "futura", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
